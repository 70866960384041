export const messages = Object.freeze({
  AURA: "Aura",
  USER: "User",
  ADMIN: "Admin",
  LOGIN: "Login",
  GETREGISTERED: "Get Registered For AURA !!",
  INSTRUCTION: " Please fill out the form below to register for Aura",
  HEADERTEXT:
    "We’re excited to introduce our intelligent assistants, here to elevate every step of your product development journey.",
  LOGOUTEXT: "Are you sure you want to log out?",
  COMINGSOON: "Coming Soon",
  SIGNIN: "Sign In",
  SUBMIT: "Submit",
  REGISTERING: "Registering",
  SIGNINDESC: `Hi there! New to the system?
     Let's personalise your AI experience.`,
  TROUBLEWHILESIGNIN: "Trouble signing in? Please",
  SHOUTOUT: "Join AURA Today and unlock the future of design-led innovation!",
  NEWUSER: "New User?",
  REGISTER: "Register Here",
  EXISTINGUSER: "Existing User?",
  LOGINHERE: "Login Here",
  CONTACTUS: "contact us!",
  INPROGRESS:
    "Provided Figma file is in progress. Please wait for the completion.",
  CHATINPROGRESS:
    "Provided Chat is in progress. Please wait for the completion.",
});
